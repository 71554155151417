import { call, put, select } from 'redux-saga/effects';
import { PRODUCTION_MODE } from '../../config';
import { Client } from '../../sdk';
import { PaymentOrder } from '../../types';
import {
  requestFailed,
  requestStarted,
  requestSucceeded,
  ConfirmTxPressedEvent,
} from '../events';
import { selectors } from '../storage';

export function* confirmTxTask({ payload: { txid } }: ConfirmTxPressedEvent) {
  const paymentOrder = (yield select(selectors.paymentOrder)) as PaymentOrder;
  const tx = paymentOrder.transactions.find(t => t.txid === txid);
  if (!tx || tx.confirmed) {
    return;
  }
  try {
    yield put(requestStarted());
    yield call(Client.confirmTx, txid);
    yield put(requestSucceeded());
  } catch (e) {
    if (!PRODUCTION_MODE) {
      console.error(e);
    }
    yield put(requestFailed());
  }
}
