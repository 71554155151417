import { Action } from 'redux';
import { SandboxWalletSocketsFactory } from 'sandbox-wallet-sdk-js';
import { Socket as SDKSocket } from 'sdk-boilerplate/lib/Socket/Socket';
import { PRODUCTION_MODE, SOCKET_URL } from '../config';
import { store } from '../logic';
import { PaymentUpdatedSocketPayload } from '../types/socket_objects';

const factory = new SandboxWalletSocketsFactory({
  hostname: SOCKET_URL,
  onFail: () => {
    if (!PRODUCTION_MODE) {
      console.error('Connection failed');
    }
  },
});

export const SocketPaymentUpdated = 'event/internal/SOCKET_PAYMENT_UPDATED';

export interface SocketPaymentUpdatedEvent extends Action {
  type: typeof SocketPaymentUpdated;
  payload: PaymentUpdatedSocketPayload;
}

function socketPaymentUpdated(
  payload: PaymentUpdatedSocketPayload
): SocketPaymentUpdatedEvent {
  return {
    type: SocketPaymentUpdated,
    payload,
  };
}

export class Socket {
  private socket: SDKSocket;
  private uuid: string;

  constructor(uuid: string) {
    this.uuid = uuid;
    this.socket = factory.make('PaymentOrderSocket');
    if (!PRODUCTION_MODE) {
      console.log('Socket created', this.socket);
    }
  }

  start = () => {
    this.socket.on('payment.updated', payload => {
      const payment = payload as PaymentUpdatedSocketPayload;
      store.dispatch(socketPaymentUpdated(payment));
    });
    this.socket.subscribe(this.uuid);
    if (!PRODUCTION_MODE) {
      console.log('Socket connected to room', this.uuid);
    }
  };

  stop = () => {
    this.socket.off('payment.updated');
    this.socket.unsubscribeAll();
  };
}
