import * as React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import './Section.css';

interface SectionProps {
  title: string;
  children: React.ReactNodeArray | React.ReactNode;
}

export const Section = ({ children, title }: SectionProps) => (
  <Grid className="Section">
    <Row className="SectionTitle">
      <Col xs={12}>{title}</Col>
    </Row>
    {children}
  </Grid>
);
