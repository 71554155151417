import * as React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Button } from '../../atoms';
import './TransactionListHeader.css';

interface TransactionListHeaderProps {
  confirmAll: () => void;
  disabled: boolean;
}

export const TransactionListHeader = ({
  confirmAll,
  disabled,
}: TransactionListHeaderProps) => (
  <Row className="TransactionListHeader" middle="xs">
    <Col xs={4} sm={5} md={6}>
      id
    </Col>
    <Col xs={4} sm={4} md={3}>
      Importo
    </Col>
    <Col xs={4} sm={3} md={3}>
      <Button disabled={disabled} onClick={confirmAll}>
        Conferma tutte
      </Button>
    </Col>
  </Row>
);
