export enum Events {
  PaymentOrderRetrieved = 'event/PAYMENT_ORDER_RETRIEVED',
  RetrievalError = 'event/RETRIEVAL_ERROR',
  PaymentOrderUpdated = 'event/PAYMENT_ORDER_UPDATED',
  RequestStarted = 'event/REQUEST_STARTED',
  RequestSucceeded = 'event/REQUEST_SUCCEEDED',
  RequestFailed = 'event/REQUEST_FAILED',
  ExpirePressed = 'event/user/EXPIRE_PRESSED',
  CreateTxPressed = 'event/user/CREATE_TX_PRESSED',
  ConfirmTxPressed = 'event/user/CONFIRM_TX_PRESSED',
  ConfirmAllPressed = 'event/user/CONFIRM_ALL_PRESSED',
  CloseModalPressed = 'event/user/CLOSE_MODAL_PRESSED',
}
