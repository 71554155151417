export enum SandboxWalletStates {
  Loading = 'sandbox-wallet/state/LOADING',
  Error = 'sandbox-wallet/state/ERROR',
  Ready = 'sandbox-wallet/state/READY',
}

export enum SandboxWalletReadyStates {
  Ready = 'sandbox-wallet-ready/state/READY',
  Sending = 'sandbox-wallet-ready/state/SENDING',
  Sent = 'sandbox-wallet-ready/state/SENT',
  Error = 'sandbox-wallet-ready/state/ERROR',
  Success = 'sandbox-wallet-ready/state/SUCCESS',
}
