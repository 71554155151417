import Decimal from 'decimal.js';
import * as React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { PRODUCTION_MODE } from '../../config';
import { Button, DecimalField, Section } from '../atoms';

interface TransactionSenderProps {
  sendTx: (amount: Decimal) => void;
  disable: boolean;
}

interface TransactionSenderState {
  value: Decimal;
}

export class TransactionSender extends React.Component<
  TransactionSenderProps,
  TransactionSenderState
> {
  state = {
    value: new Decimal(0),
  };

  sendTx = (e: React.MouseEvent) => {
    e.preventDefault();
    this.props.sendTx(this.state.value);
  };

  changeDecimalValue = (value: Decimal) => {
    if (!PRODUCTION_MODE) {
      console.log('New decimal value:', value.toString());
    }
    this.setState({ value });
  };

  render() {
    const { disable } = this.props;
    const buttonDisabled = disable || this.state.value.lessThanOrEqualTo(0);

    return (
      <Section title="Invia transazioni di test">
        <Row>
          <Col xs={12}>
            Simula l'interazione dell'utente inserendo l'importo in BTC e
            inviando una transazione di test.
          </Col>
        </Row>
        <form>
          <Row between="md">
            <Col xs={12} sm={6} md={6}>
              <DecimalField
                disable={disable}
                value={this.state.value}
                onChange={this.changeDecimalValue}
              />
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Button
                primary
                submit
                fullWidth
                disabled={buttonDisabled}
                onClick={this.sendTx}
              >
                Invia transazione
              </Button>
            </Col>
          </Row>
        </form>
      </Section>
    );
  }
}
