import Decimal from 'decimal.js';

export interface Transaction {
  txid: string;
  confirmed: boolean;
  amount: Decimal;
}

export interface PaymentOrder {
  uuid: string;
  status: PaymentOrderStatus;
  canExpire: boolean;
  amount: Decimal;
  confirmed_amount: Decimal;
  unconfirmed_amount: Decimal;
  missing_amount: Decimal;
  transactions: Transaction[];
}

export enum PaymentOrderStatus {
  pending = 'PENDING',
  paid = 'PAID',
  chargeback = 'CHARGEBACK',
  network_dispute = 'NETWORK_DISPUTE',
  expired = 'EXPIRED',
  cancelled = 'CANCELLED',
}
