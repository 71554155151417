import classNames from 'classnames';
import * as React from 'react';
import './Button.css';

interface ButtonLinkProps {
  href: string;
  target: string;
  children: string;
}

export const ButtonLink = ({ href, target, children }: ButtonLinkProps) => (
  <a
    className={classNames({
      Button: true,
      ButtonLink: true,
      Secondary: true,
      FullWidth: true,
    })}
    href={href}
    target={target}
  >
    {children}
  </a>
);
