import * as React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { PaymentOrder } from '../../types';
import { formatBTC } from '../../utils/formatBTC';
import { Button, ButtonLink, CopyToClipboard, Section } from '../atoms';
import './PaymentDetails.css';

interface PaymentDetailsProps {
  payment: PaymentOrder;
  expire: () => void;
  disable: boolean;
  checkoutPageUrl: string;
}

export const PaymentDetails = ({
  disable,
  payment,
  expire,
  checkoutPageUrl,
}: PaymentDetailsProps) => (
  <Section title="Dettagli del pagamento">
    <Row style={{ marginBottom: '8px' }}>
      <Col xs={12}>Premi sull'importo per copiarne il valore.</Col>
    </Row>
    <Row>
      <Col xs={12} sm={6}>
        <Row>
          <Col className="DetailName" xs={6}>
            Importo mancante
          </Col>
          <Col xs={6}>
            <CopyToClipboard text={formatBTC(payment.missing_amount)} />
          </Col>
        </Row>
        <Row>
          <Col className="DetailName" xs={6}>
            Importo non confermato
          </Col>
          <Col xs={6}>
            <CopyToClipboard text={formatBTC(payment.unconfirmed_amount)} />
          </Col>
        </Row>
        <Row>
          <Col className="DetailName" xs={6}>
            Importo confermato
          </Col>
          <Col xs={6}>
            <CopyToClipboard text={formatBTC(payment.confirmed_amount)} />
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={6}>
        <Row>
          <Col className="DetailName" xs={6}>
            Importo totale
          </Col>
          <Col xs={6}>
            <CopyToClipboard text={formatBTC(payment.amount)} />
          </Col>
        </Row>
        <Row>
          <Col className="DetailName" xs={6}>
            Stato
          </Col>
          <Col xs={6}>{payment.status}</Col>
        </Row>
        <Row>
          <Col className="DetailName" xs={6}>
            Id del pagamento
          </Col>
          <Col xs={6}>
            <CopyToClipboard text={payment.uuid} />
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={6}>
        <Button
          fullWidth
          disabled={disable || !payment.canExpire}
          onClick={expire}
        >
          Fai scadere il pagamento
        </Button>
      </Col>
      <Col xs={12} sm={6}>
        <ButtonLink href={checkoutPageUrl} target="_blank">
          Torna alla Checkout Page
        </ButtonLink>
      </Col>
    </Row>
  </Section>
);
