import Decimal from 'decimal.js';
import { Action } from 'redux';
import { PaymentOrder, PaymentOrderStatus } from '../../types';

enum StoreDataActionType {
  PaymentOrder = 'store/data/PAYMENT_ORDER',
  PaymentOrderUUID = 'store/data/PAYMENT_ORDER_UUID',
}

interface StoreDataAction<D> extends Action<StoreDataActionType> {
  payload: D;
}

const defaultPaymentOrder: PaymentOrder = {
  uuid: '',
  amount: new Decimal(0),
  status: PaymentOrderStatus.pending,
  missing_amount: new Decimal(0),
  confirmed_amount: new Decimal(0),
  unconfirmed_amount: new Decimal(0),
  transactions: [],
  canExpire: false,
};

/**
 * Payment Order
 */

interface StorePaymentOrderAction extends StoreDataAction<PaymentOrder> {
  type: StoreDataActionType.PaymentOrder;
}

export function storePaymentOrder(
  payment: PaymentOrder
): StorePaymentOrderAction {
  return {
    payload: payment,
    type: StoreDataActionType.PaymentOrder,
  };
}

export function paymentOrderReducer(
  state: PaymentOrder = defaultPaymentOrder,
  action: StorePaymentOrderAction
): PaymentOrder {
  if (action.type === StoreDataActionType.PaymentOrder) {
    return { ...action.payload };
  } else {
    return state;
  }
}

export const paymentOrderSelector = (state: PaymentOrder) => state;

/**
 * Payment order uuid
 */

interface StorePaymentOrderUUIDAction extends StoreDataAction<string> {
  type: StoreDataActionType.PaymentOrderUUID;
}

export function storePaymentOrderUUID(
  uuid: string
): StorePaymentOrderUUIDAction {
  return {
    payload: uuid,
    type: StoreDataActionType.PaymentOrderUUID,
  };
}

export function paymentOrderUUIDReducer(
  state: string = '',
  action: StorePaymentOrderUUIDAction
): string {
  if (action.type === StoreDataActionType.PaymentOrderUUID) {
    return action.payload;
  } else {
    return state;
  }
}

export const paymentOrderUUIDSelector = (state: string) => state;
