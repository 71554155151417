import {
  applyMiddleware,
  compose,
  createStore,
  Middleware,
  Store as ReduxStore,
} from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { PRODUCTION_MODE } from '../config';
import {
  closeModalPressed,
  confirmAll,
  confirmTx,
  createTx,
  expire,
} from './events';
import { rootSaga } from './state-machines';
import { rootReducer, Storage } from './storage';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers: typeof compose =
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // tslint:disable-line:no-unsafe-any

const middlewares: Middleware[] = [sagaMiddleware];

if (!PRODUCTION_MODE) {
  middlewares.push(logger);
}

const store: ReduxStore<Storage> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

export { store, createTx, expire, confirmTx, confirmAll, closeModalPressed };
