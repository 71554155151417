import * as React from 'react';
import { Loader, Modal } from '../../atoms';
import './Styles.css';

interface LoadingModalProps {
  text: string;
}

export const LoadingModal = (props: LoadingModalProps) => (
  <Modal isOpen>
    <Loader />
    <div className="Text">{props.text}</div>
  </Modal>
);
