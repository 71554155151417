import * as React from 'react';
import { SandboxWalletStates } from '../../logic/states';
import { Footer, Header } from '../molecules';
import { ErrorScreen, LoadingScreen, WalletScreen } from '../screens';

interface MainProps {
  state: SandboxWalletStates;
}

const ComponentMapping = {
  [SandboxWalletStates.Ready]: WalletScreen,
  [SandboxWalletStates.Loading]: LoadingScreen,
  [SandboxWalletStates.Error]: ErrorScreen,
};

export class Main extends React.Component<MainProps> {
  render() {
    const Component = ComponentMapping[this.props.state];

    return (
      <>
        <Header />
        <Component />
        <Footer />
      </>
    );
  }
}
