import * as React from 'react';
import { SandboxWalletReadyStates } from '../../logic/states';
import { ErrorModal, LoadingModal, SuccessModal } from './Modals';
import './RequestStatus.css';

interface RequestStatusProps {
  closeModal: () => void;
  status: SandboxWalletReadyStates;
}

export const RequestStatus = ({ status, closeModal }: RequestStatusProps) => {
  switch (status) {
    case SandboxWalletReadyStates.Sending:
      return <LoadingModal text="Invio richiesta in corso..." />;
    case SandboxWalletReadyStates.Sent:
      return <LoadingModal text="Attesa di conferma..." />;
    case SandboxWalletReadyStates.Error:
      return (
        <ErrorModal
          title="Richiesta fallita"
          text="Non è stato possibile aggiornare l'ordine di pagamento."
          onClick={closeModal}
        />
      );
    case SandboxWalletReadyStates.Success:
      return (
        <SuccessModal
          onClick={closeModal}
          title="Richiesta effettuata"
          text="L'ordine di pagamento è stato aggiornato."
        />
      );
    case SandboxWalletReadyStates.Ready:
    default:
      return null;
  }
};
