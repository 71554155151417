import * as React from 'react';
import ReactModal from 'react-modal';
import './Modal.css';

interface ModalProps {
  isOpen: boolean;
  dismissable?: boolean;
  children: React.ReactNode | React.ReactNodeArray;
}

export const Modal = ({ isOpen, children, dismissable }: ModalProps) => (
  <ReactModal
    className="Content"
    isOpen={isOpen}
    shouldCloseOnEsc={dismissable}
    shouldCloseOnOverlayClick={dismissable}
  >
    {children}
  </ReactModal>
);
