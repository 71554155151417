import { put, select, take } from 'redux-saga/effects';
import {
  Socket,
  SocketPaymentUpdated,
  SocketPaymentUpdatedEvent,
} from '../../sdk/Socket';
import { convertPaymentOrder } from '../../sdk/utils';
import { PaymentOrder } from '../../types';
import { paymentOrderUpdated } from '../events';
import { selectors } from '../storage';
import { storePaymentOrder } from '../storage/data';

export function* socketTask() {
  const uuid = (yield select(selectors.uuid)) as string;
  const socket = new Socket(uuid);
  socket.start();
  while (true) {
    const paymentEvent = (yield take(
      SocketPaymentUpdated
    )) as SocketPaymentUpdatedEvent;
    const newPaymentOrder: PaymentOrder = convertPaymentOrder(
      paymentEvent.payload
    );
    yield put(paymentOrderUpdated(newPaymentOrder));
    yield put(storePaymentOrder(newPaymentOrder));
  }
}
