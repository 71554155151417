import { createStateMachine, StateMachine } from 'redux-stm-act/build';
import { SandboxWalletStates } from '../states';
import { storeSandboxWalletState } from '../storage/state';
import {
  confirmAllTask,
  confirmTxTask,
  createTxTask,
  expirePaymentOrderTask,
  retrievePaymentOrderTask,
  socketTask,
  storePaymentOrderTask,
} from '../tasks';
import { Events } from '../types';
import { sandboxWalletReady } from './sandboxWalletReady';

const sandboxWalletSpec: StateMachine<SandboxWalletStates, Events> = {
  [SandboxWalletStates.Loading]: {
    onEntry: retrievePaymentOrderTask,
    transitions: {
      [Events.RetrievalError]: {
        target: SandboxWalletStates.Error,
      },
      [Events.PaymentOrderRetrieved]: {
        target: SandboxWalletStates.Ready,
        command: storePaymentOrderTask,
      },
    },
  },
  [SandboxWalletStates.Error]: {},
  [SandboxWalletStates.Ready]: {
    onEntry: socketTask,
    reactions: {
      [Events.ExpirePressed]: expirePaymentOrderTask,
      [Events.CreateTxPressed]: createTxTask,
      [Events.ConfirmTxPressed]: confirmTxTask,
      [Events.ConfirmAllPressed]: confirmAllTask,
    },
    subMachines: [sandboxWalletReady],
  },
};

export const sandboxWallet = createStateMachine(
  sandboxWalletSpec,
  SandboxWalletStates.Loading,
  storeSandboxWalletState
);
