import * as React from 'react';
import { Transaction } from '../../../types';
import { Section } from '../../atoms';
import { NoTransactions } from './NoTransactions';
import { TransactionListHeader } from './TransactionListHeader';
import { TransactionRow } from './TransactionRow';

interface TransactionListProps {
  transactions: Transaction[];
  confirmTx: (txid: string) => void;
  confirmAll: () => void;
  allConfirmed: boolean;
  disable: boolean;
}

export class TransactionList extends React.Component<TransactionListProps> {
  render() {
    const {
      confirmTx,
      confirmAll,
      transactions,
      disable,
      allConfirmed,
    } = this.props;

    return (
      <Section title="Transazioni">
        {transactions.length === 0 && <NoTransactions />}
        {transactions.length > 0 && (
          <>
            <TransactionListHeader
              disabled={disable || allConfirmed}
              confirmAll={confirmAll}
            />
            {transactions.map(tx => (
              <TransactionRow
                disable={disable}
                key={tx.txid}
                transaction={tx}
                onConfirm={confirmTx}
              />
            ))}
          </>
        )}
      </Section>
    );
  }
}
