import * as React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Transaction } from '../../../types';
import { formatBTC } from '../../../utils/formatBTC';
import { Button } from '../../atoms';
import './TransactionRow.css';

interface TransactionRowProps {
  transaction: Transaction;
  onConfirm: (txid: string) => void;
  disable: boolean;
}

export class TransactionRow extends React.Component<TransactionRowProps> {
  confirmTx = () => {
    this.props.onConfirm(this.props.transaction.txid);
  };

  render() {
    const {
      disable,
      transaction: { confirmed, txid, amount },
    } = this.props;

    return (
      <Row className="TransactionRow">
        <Col className="idTransaction TextCell" xs={4} sm={5} md={6}>
          {txid}
        </Col>
        <Col className="TextCell" xs={4} sm={4} md={3}>
          {formatBTC(amount)}
        </Col>
        <Col className="ButtonCell" xs={4} sm={3} md={3}>
          <Button disabled={disable || confirmed} onClick={this.confirmTx}>
            Conferma
          </Button>
        </Col>
      </Row>
    );
  }
}
