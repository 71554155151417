import { call, put, select } from 'redux-saga/effects';
import { PRODUCTION_MODE } from '../../config';
import { Client } from '../../sdk';
import { PaymentOrder } from '../../types';
import { requestFailed, requestStarted, requestSucceeded } from '../events';
import { selectors } from '../storage';

export function* confirmAllTask() {
  const paymentOrder = (yield select(selectors.paymentOrder)) as PaymentOrder;
  const unconfirmedTransactions = paymentOrder.transactions.filter(
    tx => !tx.confirmed
  );
  if (unconfirmedTransactions.length <= 0) {
    return;
  }
  try {
    yield put(requestStarted());
    const uuid = (yield select(selectors.uuid)) as string;
    yield call(Client.confirmAll, uuid);
    yield put(requestSucceeded());
  } catch (e) {
    if (!PRODUCTION_MODE) {
      console.error(e);
    }
    yield put(requestFailed());
  }
}
