import * as React from 'react';
import { CopyToClipboard as CTC } from 'react-copy-to-clipboard';
import './CopyToClipboard.css';

interface CopyToClipboardProps {
  text: string;
}

interface CopyToClipboardState {
  copied: boolean;
  timeout?: number;
}

export class CopyToClipboard extends React.Component<
  CopyToClipboardProps,
  CopyToClipboardState
> {
  state = {
    copied: false,
    timeout: undefined,
  };

  onCopy = () => {
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }
    this.setState(
      {
        copied: true,
        timeout: undefined,
      },
      () => {
        const timeoutId = setTimeout(() => {
          this.setState({ copied: false });
        }, 1000);
        this.setState({
          timeout: timeoutId,
        });
      }
    );
  };

  render() {
    return (
      <CTC text={this.props.text} onCopy={this.onCopy}>
        <span className="CopyToClipboard">
          {this.state.copied ? 'copiato' : this.props.text}
        </span>
      </CTC>
    );
  }
}
