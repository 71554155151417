import { call, put, select } from 'redux-saga/effects';
import { PRODUCTION_MODE } from '../../config';
import { Client } from '../../sdk';
import {
  requestFailed,
  requestStarted,
  requestSucceeded,
  CreateTxPressedEvent,
} from '../events';
import { selectors } from '../storage';

export function* createTxTask({ payload: { amount } }: CreateTxPressedEvent) {
  try {
    yield put(requestStarted());
    const uuid = (yield select(selectors.uuid)) as string;
    yield call(Client.newTx, uuid, amount);
    yield put(requestSucceeded());
  } catch (e) {
    if (!PRODUCTION_MODE) {
      console.error(e);
    }
    yield put(requestFailed());
  }
}
