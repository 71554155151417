import Decimal from 'decimal.js';

export function formatBTC(amount: Decimal): string {
  return amount
    .toDecimalPlaces(8)
    .toNumber()
    .toLocaleString('en-US', {
      maximumFractionDigits: 8,
      minimumFractionDigits: 0,
      useGrouping: false,
    });
}
