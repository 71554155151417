import classNames from 'classnames';
import * as React from 'react';
import './Button.css';

interface ButtonProps {
  disabled?: boolean;
  onClick: (args: React.MouseEvent) => void;
  submit?: boolean;
  primary?: boolean;
  big?: boolean;
  small?: boolean;
  children: string;
  fullWidth?: boolean;
  style?: React.CSSProperties;
}

export const Button = ({
  disabled,
  children,
  onClick,
  submit,
  fullWidth,
  style,
  big,
  primary,
}: ButtonProps) => (
  <button
    style={style}
    className={classNames({
      Button: true,
      Big: big,
      Primary: primary,
      Secondary: !primary,
      FullWidth: fullWidth,
    })}
    disabled={disabled}
    onClick={onClick}
    type={submit ? 'submit' : 'button'}
  >
    {children}
  </button>
);
