import { connect } from 'react-redux';
import {
  closeModalPressed,
  confirmAll,
  confirmTx,
  createTx,
  expire,
} from '../../logic';
import { Storage } from '../../logic/storage';
import { ErrorScreen } from './ErrorScreen';
import { LoadingScreen } from './LoadingScreen';
import { WalletScreen } from './WalletScreen';

const mapStateToProps = (state: Storage) => ({
  payment: state.data.paymentOrder,
  state: state.state.sandboxWalletReady,
});

const mapDispatchToProps = {
  expire,
  sendTx: createTx,
  confirmTx,
  confirmAll,
  closeModal: closeModalPressed,
};

const ConnectedWalletScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletScreen);

export { ErrorScreen, LoadingScreen, ConnectedWalletScreen as WalletScreen };
