import Decimal from 'decimal.js';
import { PaymentOrder, PaymentOrderStatus } from '../types';
import { GetPaymentOrderCheckoutDetailsResponseObject } from '../types/action_objects';
import { PaymentUpdatedSocketPayload } from '../types/socket_objects';

export function mapStatus(
  po: GetPaymentOrderCheckoutDetailsResponseObject | PaymentUpdatedSocketPayload
): PaymentOrderStatus {
  switch (po.state.blockchain_status) {
    case 'cancelled':
      return PaymentOrderStatus.cancelled;
    case 'chargeback':
      return PaymentOrderStatus.chargeback;
    case 'expired':
      return PaymentOrderStatus.expired;
    case 'mempool_network_dispute':
    case 'network_dispute':
    case 'possible_chargeback':
      return PaymentOrderStatus.network_dispute;
    case 'pending':
    case 'partial':
    case 'mempool_unconfirmed':
    case 'unconfirmed':
      return PaymentOrderStatus.pending;
    case 'paid':
    default:
      return PaymentOrderStatus.paid;
  }
}

export function convertPaymentOrder(
  payment:
    | GetPaymentOrderCheckoutDetailsResponseObject
    | PaymentUpdatedSocketPayload
): PaymentOrder {
  const txs = payment.transactions ? payment.transactions : [];
  txs.sort();

  return {
    amount: satoshiToBtc(new Decimal(payment.crypto_amount ? payment.crypto_amount: 0)),
    canExpire:
      payment.state.blockchain_status === 'pending' ||
      payment.state.blockchain_status === 'partial',
    confirmed_amount: payment.state.paid
      ? satoshiToBtc(new Decimal(payment.state.paid.crypto))
      : new Decimal(0),
    unconfirmed_amount: payment.state.in_confirmation
      ? satoshiToBtc(new Decimal(payment.state.in_confirmation.crypto))
      : new Decimal(0),
    missing_amount: payment.state.unpaid
      ? satoshiToBtc(new Decimal(payment.state.unpaid.crypto))
      : new Decimal(0),
    status: mapStatus(payment),
    uuid: payment.uuid,
    transactions: txs
      .filter(tx => tx.blockchain_status !== 'reverted')
      .map(tx => ({
        txid: tx.txid? tx.txid: "",
        amount: satoshiToBtc(new Decimal(tx.outs_sum)),
        confirmed: tx.status === 'confirmed',
      })),
  };
}

export function satoshiToBtc(satoshi: Decimal): Decimal {
  return satoshi.dividedBy(Decimal.pow(10, 8));
}

export function btcToSatoshi(btc: Decimal): Decimal {
  return btc.times(Decimal.pow(10, 8)).toDecimalPlaces(0);
}
