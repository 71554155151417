import { call, put } from 'redux-saga/effects';
import { PAYMENT_ORDER_ID_URL_REGEX, PRODUCTION_MODE } from '../../config';
import { Client } from '../../sdk';
import { PaymentOrder } from '../../types';
import { paymentOrderRetrieved, retrievalError } from '../events';
import { storePaymentOrderUUID } from '../storage/data';

function getPaymentID(): string {
  const match = window.location.href.match(PAYMENT_ORDER_ID_URL_REGEX);
  if (!match) {
    throw new Error('Missing match');
  }

  return match[1];
}

export function* retrievePaymentOrderTask() {
  try {
    const uuid = getPaymentID();
    yield put(storePaymentOrderUUID(uuid));
    const payment = (yield call(Client.getPaymentOrder, uuid)) as PaymentOrder;
    yield put(paymentOrderRetrieved(payment));
  } catch (e) {
    if (!PRODUCTION_MODE) {
      console.error(e);
    }
    yield put(retrievalError());
  }
}
