import Decimal from 'decimal.js';
import { Action } from 'redux';
import { PaymentOrder } from '../types';
import { Events } from './types';

export interface Event extends Action<Events> {}

/**
 * Event interfaces
 */

export interface PaymentOrderRetrievedEvent extends Event {
  type: Events.PaymentOrderRetrieved;
  payload: PaymentOrder;
}

export interface RetrievalErrorEvent extends Event {
  type: Events.RetrievalError;
}

export interface PaymentOrderUpdatedEvent extends Event {
  type: Events.PaymentOrderUpdated;
  payload: PaymentOrder;
}

export interface RequestStartedEvent extends Event {
  type: Events.RequestStarted;
}

export interface RequestSucceededEvent extends Event {
  type: Events.RequestSucceeded;
}

export interface RequestFailedEvent extends Event {
  type: Events.RequestFailed;
}

export interface CreateTxPressedEvent extends Event {
  type: Events.CreateTxPressed;
  payload: {
    amount: Decimal;
  };
}

export interface ConfirmTxPressedEvent extends Event {
  type: Events.ConfirmTxPressed;
  payload: {
    txid: string;
  };
}

export interface ConfirmAllPressedEvent extends Event {
  type: Events.ConfirmAllPressed;
}

export interface ExpirePressedEvent extends Event {
  type: Events.ExpirePressed;
}

export interface CloseModalPressedEvent extends Event {
  type: Events.CloseModalPressed;
}

/**
 * Event creators
 */

export function paymentOrderRetrieved(
  payment: PaymentOrder
): PaymentOrderRetrievedEvent {
  return {
    type: Events.PaymentOrderRetrieved,
    payload: payment,
  };
}

export function retrievalError(): RetrievalErrorEvent {
  return {
    type: Events.RetrievalError,
  };
}

export function paymentOrderUpdated(
  payment: PaymentOrder
): PaymentOrderUpdatedEvent {
  return {
    type: Events.PaymentOrderUpdated,
    payload: payment,
  };
}

export function requestStarted(): RequestStartedEvent {
  return {
    type: Events.RequestStarted,
  };
}

export function requestSucceeded(): RequestSucceededEvent {
  return {
    type: Events.RequestSucceeded,
  };
}

export function requestFailed(): RequestFailedEvent {
  return {
    type: Events.RequestFailed,
  };
}

export function confirmTx(txid: string): ConfirmTxPressedEvent {
  return {
    type: Events.ConfirmTxPressed,
    payload: { txid },
  };
}

export function confirmAll(): ConfirmAllPressedEvent {
  return {
    type: Events.ConfirmAllPressed,
  };
}

export function expire(): ExpirePressedEvent {
  return {
    type: Events.ExpirePressed,
  };
}

export function createTx(amount: Decimal): CreateTxPressedEvent {
  return {
    type: Events.CreateTxPressed,
    payload: { amount },
  };
}

export function closeModalPressed(): CloseModalPressedEvent {
  return {
    type: Events.CloseModalPressed,
  };
}
