import { Decimal } from 'decimal.js';
import * as React from 'react';
import './DecimalField.css';

interface DecimalFieldProps {
  value: Decimal;
  onChange: (value: Decimal) => void;
  disable: boolean;
}

interface DecimalFieldState {
  textValue: string;
}

export class DecimalField extends React.Component<
  DecimalFieldProps,
  DecimalFieldState
> {
  state = {
    textValue: '',
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!/^(0|[1-9]\d{0,6})?(\.\d{0,8})?$/.test(value)) {
      return;
    }
    try {
      const newDecimalValue = new Decimal(value.length ? value : 0);
      this.setState(
        {
          textValue: value,
        },
        () => this.props.onChange(newDecimalValue)
      );
    } catch (err) {
      return;
    }
  };

  render() {
    return (
      <input
        className="DecimalField"
        placeholder="Inserisci l'importo in BTC"
        disabled={this.props.disable}
        type="text"
        onChange={this.onChange}
        value={this.state.textValue}
      />
    );
  }
}
