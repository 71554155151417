import * as React from 'react';
import './ErrorMessage.css';

export const ErrorMessage = () => (
  <div className="ErrorMessage">
    <img
      className="ErrorIcon"
      alt="Errore"
      src={require('../../img/cancelled.png') as string}
    />
    <div className="ErrorMessageText">
      Impossibile recuperare l'ordine di pagamento.
    </div>
  </div>
);
