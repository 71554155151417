import { envValidation as env } from 'env-validation-js';

/**
 * The regular expression used to match the payment order id in the checkout
 * page url.
 *
 * This RegExp takes as payment order id everything from the last slash to
 * the end of the string.
 */
const PAYMENT_ORDER_ID_URL_REGEX: RegExp = new RegExp(
  env.get('REACT_APP_PAYMENT_ORDER_ID_URL_REGEX', '/([^/]+)$').asString()
);

/**
 * Socket server URL.
 */
const SOCKET_URL: string = env.get('REACT_APP_SOCKET_URL').asUrl();

/**
 * Checkout Page back end URL.
 */
const API_URL: string = env.get('REACT_APP_API_URL').asUrl();

/**
 * Use credential for authentication.
 */
const SDK_AUTH_WITH_CREDENTIALS = env
  .get('REACT_APP_SDK_AUTH_WITH_CREDENTIALS', true)
  .asBoolean();

/**
 * Use credential for authentication.
 */
const SKIP_HTTPS_VALIDATION = env
  .get('REACT_APP_SKIP_HTTPS_VALIDATION', false)
  .asBoolean();

/**
 * True if in production mode, false if in development mode.
 */
const PRODUCTION_MODE: boolean =
  env
    .get('NODE_ENV', 'production')
    .asString('in:production,development,test') === 'production';

/**
 * URL to go back to the Checkout Page.
 */
const CHECKOUT_PAGE_BASE_URL = env
  .get('REACT_APP_CHECKOUT_PAGE_BASE_URL')
  .asUrl();

export {
  API_URL,
  CHECKOUT_PAGE_BASE_URL,
  PAYMENT_ORDER_ID_URL_REGEX,
  PRODUCTION_MODE,
  SDK_AUTH_WITH_CREDENTIALS,
  SKIP_HTTPS_VALIDATION,
  SOCKET_URL,
};
