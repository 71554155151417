import { StoreStateAction as StoreStateActionGeneric } from 'redux-stm-act/build/utils/createStateMachine';
import { SandboxWalletReadyStates, SandboxWalletStates } from '../states';

export enum StoreStateActionType {
  SandboxWallet = 'store/state/SANDBOX_WALLET',
  SandboxWalletReady = 'store/state/SANDBOX_WALLET_READY',
}

interface StoreStateAction<S extends string>
  extends StoreStateActionGeneric<S, StoreStateActionType> {}

interface StoreSandboxWalletStateAction
  extends StoreStateAction<SandboxWalletStates> {
  type: StoreStateActionType.SandboxWallet;
}

interface StoreSandboxWalletReadyStateAction
  extends StoreStateAction<SandboxWalletReadyStates> {
  type: StoreStateActionType.SandboxWalletReady;
}

export function storeSandboxWalletState(
  state: SandboxWalletStates
): StoreSandboxWalletStateAction {
  return {
    type: StoreStateActionType.SandboxWallet,
    payload: state,
  };
}

export function SandboxWalletReducer(
  state: SandboxWalletStates = SandboxWalletStates.Loading,
  action: StoreSandboxWalletStateAction
) {
  if (action.type === StoreStateActionType.SandboxWallet) {
    return action.payload;
  } else {
    return state;
  }
}

export function storeSandboxWalletReadyState(
  state: SandboxWalletReadyStates
): StoreSandboxWalletReadyStateAction {
  return {
    type: StoreStateActionType.SandboxWalletReady,
    payload: state,
  };
}

export function SandboxWalletReadyReducer(
  state: SandboxWalletReadyStates = SandboxWalletReadyStates.Ready,
  action: StoreSandboxWalletReadyStateAction
) {
  if (action.type === StoreStateActionType.SandboxWalletReady) {
    return action.payload;
  } else {
    return state;
  }
}
