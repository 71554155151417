import * as React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import './Footer.css';

export const Footer = () => (
  <footer>
    <Grid>
      <Row center="xs" start="sm">
        <Col className="FooterColumn" xs={12} sm={5} md={6}>
          <img
            style={{
              maxWidth: '150px',
            }}
            alt="Chainsidepay Sandbox Wallet"
            src={require('../../img/chainsidepay_sandbox_white.png') as string}
          />
          <div>
            Copyright &copy; 2018-
            {new Date().getFullYear()}
          </div>
        </Col>
        <Col className="FooterColumn" xs={12} sm={4} md={3}>
          <div className="linksTitle">Supporto</div>
          <div>
            <a href="https://chainside.net/">ChainsidePay</a>
          </div>
          <div>
            <a href="https://chainside.net/domande-frequenti">
              Domande Frequenti
            </a>
          </div>
          <div>
            <a href="https://www.chainside.net/contattaci/">Contattaci</a>
          </div>
        </Col>
        <Col className="FooterColumn" xs={12} sm={3} md={3}>
          <div className="linksTitle">Condizioni</div>
          <div>
            <a href="https://www.chainside.net/termini/">Termini</a>
          </div>
          <div>
            <a href="https://www.chainside.net/privacy/">Privacy</a>
          </div>
          <div>
            <a href="https://www.chainside.net/cookie/">Cookie Policy</a>
          </div>
        </Col>
      </Row>
    </Grid>
  </footer>
);
