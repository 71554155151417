import * as React from 'react';
import './Loader.css';

export const Loader = () => (
  <img
    alt="Caricamento"
    className="loader"
    src={require('../../img/loader.png') as string}
  />
);
