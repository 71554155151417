import * as React from 'react';
import { Loader } from '../atoms';
import './LoadingMessage.css';

export const LoadingMessage = () => (
  <div className="LoadingMessage">
    <Loader />
    <div className="LoadingMessageText">
      Caricamento ordine di pagamento in corso...
    </div>
  </div>
);
