import Decimal from 'decimal.js';
import * as React from 'react';
import { CHECKOUT_PAGE_BASE_URL } from '../../config';
import { SandboxWalletReadyStates } from '../../logic/states';
import { PaymentOrder } from '../../types';
import {
  PaymentDetails,
  RequestStatus,
  TransactionList,
  TransactionSender,
} from '../molecules';

interface WalletScreenProps {
  payment: PaymentOrder;
  expire: () => void;
  sendTx: (amount: Decimal) => void;
  confirmTx: (txid: string) => void;
  confirmAll: () => void;
  closeModal: () => void;
  state: SandboxWalletReadyStates;
}

export class WalletScreen extends React.Component<WalletScreenProps> {
  render() {
    const {
      payment,
      expire,
      sendTx,
      confirmTx,
      state,
      confirmAll,
      closeModal,
    } = this.props;

    const allConfirmed = payment.transactions.every(tx => tx.confirmed);

    const disableButtons = state !== SandboxWalletReadyStates.Ready;

    const checkoutPageUrl = `${CHECKOUT_PAGE_BASE_URL}/${payment.uuid}`;

    return (
      <div
        style={{
          marginTop: '20px',
          marginBottom: '40px',
          width: '100%',
          flex: '1 1 auto',
        }}
      >
        <RequestStatus status={state} closeModal={closeModal} />
        <PaymentDetails
          disable={disableButtons}
          payment={payment}
          expire={expire}
          checkoutPageUrl={checkoutPageUrl}
        />
        <TransactionSender disable={disableButtons} sendTx={sendTx} />
        <TransactionList
          allConfirmed={allConfirmed}
          disable={disableButtons}
          confirmTx={confirmTx}
          confirmAll={confirmAll}
          transactions={payment.transactions}
        />
      </div>
    );
  }
}
