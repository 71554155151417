import { connect } from 'react-redux';
import { Storage } from '../../logic/storage';
import { Main } from './Main';

const mapStateToProps = (state: Storage) => ({
  state: state.state.sandboxWallet,
});

const ConnectedMain = connect(mapStateToProps)(Main);

export { ConnectedMain as Main };
