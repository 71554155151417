// tslint:disable:no-unsafe-any
import Decimal from 'decimal.js';
import { SandboxWalletActionsFactory } from 'sandbox-wallet-sdk-js';
import { ActionObject } from 'sdk-boilerplate/lib/Action/ActionObject';
import {
  API_URL,
  SDK_AUTH_WITH_CREDENTIALS,
  SKIP_HTTPS_VALIDATION,
} from '../config';
import { PaymentOrder } from '../types';
import { GetPaymentOrderCheckoutDetailsResponseObject } from '../types/action_objects';
import { btcToSatoshi, convertPaymentOrder } from './utils';

const factory = new SandboxWalletActionsFactory({
  context: {
    hostname: API_URL,
    skipHTTPSvalidation: SKIP_HTTPS_VALIDATION,
    withCredentials: SDK_AUTH_WITH_CREDENTIALS,
  },
});

export class Client {
  static async getPaymentOrder(uuid: string): Promise<PaymentOrder> {
    const action = factory.make('GetPaymentOrderCheckoutDetailsAction');
    action.routeParams = {
      payment_order_uuid: uuid,
    };
    const res: GetPaymentOrderCheckoutDetailsResponseObject = await action.run();

    return convertPaymentOrder(res);
  }

  static async newTx(uuid: string, amount: Decimal): Promise<void> {
    const satoshi: number = btcToSatoshi(amount).toNumber();
    const action = factory.make('CreateNewTxAction');
    action.routeParams = {
      payment_order_uuid: uuid,
    };
    const requestClass = SandboxWalletActionsFactory.actions.CreateNewTxAction
      .requestClass as typeof ActionObject;
    action.requestBody = requestClass.fromJson({
      amount: satoshi,
    });

    return await action.run();
  }

  static async confirmTx(txid: string): Promise<void> {
    const action = factory.make('ConfirmATransactionAction');
    action.routeParams = {
      txid,
    };

    return await action.run();
  }

  static async confirmAll(uuid: string): Promise<void> {
    const action = factory.make('ConfirmAllTransactionsAction');
    action.routeParams = {
      payment_order_uuid: uuid,
    };

    return await action.run();
  }

  static async expire(uuid: string): Promise<void> {
    const action = factory.make('ExpirePaymentOrderAction');
    action.routeParams = {
      payment_order_uuid: uuid,
    };

    return await action.run();
  }
}
