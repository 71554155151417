import { combineReducers } from 'redux';
import { FirstArgumentType } from 'redux-stm-act/build';
import {
  paymentOrderReducer,
  paymentOrderSelector,
  paymentOrderUUIDReducer,
  paymentOrderUUIDSelector,
} from './data';
import { SandboxWalletReadyReducer, SandboxWalletReducer } from './state';

const dataReducer = combineReducers({
  paymentOrder: paymentOrderReducer,
  uuid: paymentOrderUUIDReducer,
});

const stateReducer = combineReducers({
  sandboxWallet: SandboxWalletReducer,
  sandboxWalletReady: SandboxWalletReadyReducer,
});

export const rootReducer = combineReducers({
  data: dataReducer,
  state: stateReducer,
});

export type Storage = NonNullable<FirstArgumentType<typeof rootReducer>>;

export const selectors = {
  paymentOrder: (state: Storage) =>
    paymentOrderSelector(state.data.paymentOrder),
  state: (state: Storage) => state.state.sandboxWallet,
  uuid: (state: Storage) => paymentOrderUUIDSelector(state.data.uuid),
};
