import * as React from 'react';
import { colors } from '../../styles/theme';

export const Header = () => (
  <header
    style={{
      backgroundColor: colors.bgNavBar,
      height: 60,
      paddingTop: 10,
      paddingBottom: 10,
      textAlign: 'center',
      alignSelf: 'flex-start',
      width: '100%',
    }}
  >
    <img
      alt="Chainsidepay Sandbox Wallet"
      style={{
        height: 60,
      }}
      src={require('../../img/chainsidepay_sandbox_white.png') as string}
    />
  </header>
);
