import * as React from 'react';
import { Button, Modal } from '../../atoms';
import './Styles.css';

interface ErrorModalProps {
  title: string;
  text: string;
  onClick: () => void;
}

export const ErrorModal = (props: ErrorModalProps) => (
  <Modal isOpen>
    <div className="Info">
      <img
        alt="Errore"
        className="Icon"
        src={require('../../../img/cancelled.png') as string}
      />
      <div className="ErrorTitle">{props.title}</div>
      <div className="Text">{props.text}</div>
    </div>
    <Button
      style={{ alignSelf: 'flex-end' }}
      big
      primary
      fullWidth
      onClick={props.onClick}
    >
      Chiudi
    </Button>
  </Modal>
);
