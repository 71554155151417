import { createStateMachine, StateMachine } from 'redux-stm-act/build';
import { SandboxWalletReadyStates } from '../states';
import { storeSandboxWalletReadyState } from '../storage/state';
import { Events } from '../types';

const sandboxWalletReadySpec: StateMachine<SandboxWalletReadyStates, Events> = {
  [SandboxWalletReadyStates.Ready]: {
    transitions: {
      [Events.RequestStarted]: {
        target: SandboxWalletReadyStates.Sending,
      },
    },
  },
  [SandboxWalletReadyStates.Sending]: {
    transitions: {
      [Events.RequestSucceeded]: {
        target: SandboxWalletReadyStates.Sent,
      },
      [Events.RequestFailed]: {
        target: SandboxWalletReadyStates.Error,
      },
    },
  },
  [SandboxWalletReadyStates.Sent]: {
    transitions: {
      [Events.PaymentOrderUpdated]: {
        target: SandboxWalletReadyStates.Success,
      },
    },
  },
  [SandboxWalletReadyStates.Error]: {
    transitions: {
      [Events.CloseModalPressed]: {
        target: SandboxWalletReadyStates.Ready,
      },
    },
  },
  [SandboxWalletReadyStates.Success]: {
    transitions: {
      [Events.CloseModalPressed]: {
        target: SandboxWalletReadyStates.Ready,
      },
    },
  },
};

export const sandboxWalletReady = createStateMachine(
  sandboxWalletReadySpec,
  SandboxWalletReadyStates.Ready,
  storeSandboxWalletReadyState
);
